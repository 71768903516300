import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import { useState, useCallback } from 'react';

import { buildCssVar } from 'utils/style-override';
import { useTextFontOverrides } from 'utils/font-override';
import { matterIdentify } from 'utils/matter-identify';
import { onFooterSignup, onFocusFooter } from 'utils/gtm';
import * as rudder from 'utils/rudderstack';
import { useFunnelData } from 'utils/funnel-data-context';

import componentStyle from './SubscriptionForm.module.css';

const MarkdownText = dynamic(() => import('./MarkdownText'));

const propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  listId: PropTypes.string,
  buttonText: PropTypes.string,
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
  buttonColor: PropTypes.string,
  buttonTextColor: PropTypes.string,
  buttonBorderColor: PropTypes.string,
  buttonOnHoverColor: PropTypes.string,
  buttonOnHoverTextColor: PropTypes.string,
  buttonOnHoverBorderColor: PropTypes.string,
  textFont: PropTypes.shape({ family: PropTypes.string }),
  styles: PropTypes.object,
};

const defaultProps = {
  title: null,
  description: null,
  listId: null,
  buttonText: null,
  backgroundColor: null,
  textColor: null,
  buttonColor: null,
  buttonTextColor: null,
  buttonBorderColor: null,
  buttonOnHoverColor: null,
  buttonOnHoverTextColor: null,
  buttonOnHoverBorderColor: null,
  textFont: null,
  styles: componentStyle,
};

const SubscriptionForm = ({
  title,
  description,
  listId,
  buttonText,
  backgroundColor,
  textColor,
  buttonColor,
  buttonTextColor,
  buttonBorderColor,
  buttonOnHoverColor,
  buttonOnHoverTextColor,
  buttonOnHoverBorderColor,
  textFont,
  styles,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const funnelData = useFunnelData();
  const { funnelId, apiBaseUrl, offerData } = funnelData;

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [apiError, setApiError] = useState(null);
  const klaviyoApiKey = process.env.NEXT_PUBLIC_KLAVIYO_PRIVATE_API_KEY;

  const [hasFocused, setHasFocusedOn] = useState([]);

  const handleFocus = useCallback(({ target }) => {
    const name = target.name;

    if (!name || hasFocused.includes(name)) return;

    onFocusFooter(name);
    rudder.onFocusFooter(name);

    setHasFocusedOn(hasFocused.concat(name));
  }, []);

  const onSubmit = async data => {
    setLoading(true);
    setApiError(null);
    setSuccess(false);

    matterIdentify({
      customerData: data,
      apiBaseUrl,
      funnelId,
    })
      .then(lead => {
        onFooterSignup(data.email, null, offerData, lead?.id);
        rudder.onFooterSignup(data.email, null, offerData, lead?.id);
      })
      .catch(() => {
        onFooterSignup(data.email, null, offerData);
        rudder.onFooterSignup(data.email, null, offerData);
      });

    try {
      const response = await fetch('/api/klaviyo-subscribe', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          klaviyoApiKey,
          listId,
          email: data.email,
        }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Klaviyo API error: ${errorText}`);
      }

      setSuccess(true);
      setApiError(null);
    } catch (error) {
      setApiError(error.message);
    }

    setLoading(false);
  };

  const textFontOverrides = useTextFontOverrides(textFont?.family);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={`root ${styles.form}`}>
      <style jsx>{`
        .root {
          ${buildCssVar('--background-color', backgroundColor, 'transparent')}
          ${buildCssVar('--text-color', textColor, 'var(--text-color-light)')}
          ${buildCssVar('--button-color', buttonColor, '#fff')}
          ${buildCssVar('--button-text-color', buttonTextColor, 'var(--text-color-dark)')}
          ${buildCssVar('--button-border-color', buttonBorderColor, '#fff')}
          ${buildCssVar('--button-on-hover-color', buttonOnHoverColor, 'transparent')}
          ${buildCssVar(
            '--button-on-hover-text-color',
            buttonOnHoverTextColor,
            'var(--text-color-light)'
          )}
          ${buildCssVar('--button-on-hover-border-color', buttonOnHoverBorderColor, '#fff')}

          ${textFontOverrides ?? ''}
        }
      `}</style>
      <div className={styles.wrapper}>
        <div className={styles.text_wrapper}>
          <h1>{title}</h1>
          {description && <MarkdownText text={description} />}
        </div>
        <div className={styles.input_btn_wrapper}>
          <input
            type="text"
            placeholder="Insert your email"
            {...register('email', {
              required: 'Please enter your email address.',
              pattern: {
                value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/,
                message: 'Please enter a valid email address.',
              },
            })}
            onFocus={handleFocus}
            className={styles.input}
          />
          {errors.email && <p className={styles.error_msg}>{errors.email.message}</p>}
          {apiError && <p className={styles.api_error}>{apiError}</p>}
          {success && <p className={styles.success}>Subscription successful!</p>}
          {!success && (
            <button type="submit" className={styles.button}>
              {loading ? 'Submitting...' : `${buttonText}`}
            </button>
          )}
        </div>
      </div>
    </form>
  );
};

SubscriptionForm.propTypes = propTypes;
SubscriptionForm.defaultProps = defaultProps;

export default SubscriptionForm;
